import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import copyImg from '../../assets/images/copy.png'
import {
  addNews,
  getNews,
  editNews,
  editedNewsUpdate,
  getEventQuestions,
  editEventQuestions,
  addEventQuestions,
  deleteEventQuestions,
  getEventRequests,
  editEventRequest,
  getQuestionAnswers,
  getEventStages,
  addEventStage,
  editEventStage,
  deleteEventStage,
  getListForExcel,
  getCurrentNew,
  deleteFile,
  getEventFiles,
  uploadEventFiles,
  deleteEventFile,
  getDepartments,
  setCurrentEvent,
  getCategories,
  saveAndClick,
  removeCategories,
  getAuthors,
  removeStageRequest,
  getAnimations,
} from "../../actions/news";
import { getRegions } from "../../actions/users";
import { getTaskObject } from "../../actions/task";
import moment from "moment";
import DatePickerField from "../../components/shared/DatePickerField";
import CheckboxField from "../../components/shared/CheckboxField";
import ImageField from "../../components/shared/ImageField";
import { PATHS } from "../../routing/Routes";
import TextField from "../../components/shared/TextField";
import WysiwygField from "../../components/shared/WysiwygField";
import DropdownField from "../../components/shared/DropdownField";
import SelectAndSearch from "../../components/shared/SelectAndSearch";
import styles from "./AddEvent.module.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import QuestionsMode from "./QuestionsMode";
import RequestsMode from "./RequestsMode";
import TextAreaField from "../../components/shared/TextAreaField";
import { eventLevels } from "../../utils/const";
import FilesMode from "./FilesMode";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import Loader from "../../components/Loader/Loader";
import { icons } from "../../utils/eventIcons";
import { createNotification } from "../../components/Notifications/Notifications";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoIosAdd, IoIosClose } from "react-icons/io";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import { Link } from "react-router-dom";
import AwardsBlock from "../../components/AwardsBlock/AwardsBlock";

const fiels = [
  "image",
  "description",
  "title",
  "linktext",
  "linkurl",
  "participantlimit",
  "articledate",
  "pubdate",
  "fromdate",
  "todate",
];

const eventTypes = [
  { label: "Мероприятие", value: 2 },
  { label: "Социально значимый опрос", value: 4 },
];

const eventPlug = {
  articletype: 2,
  level: 1,
  show_result: true,
  external_participation: false
}

const AddEvent = ({
  userId,
  formValue,
  initialValues,
  handleSubmit,
  change,
  pathname,
  editedNewsUpdate,
  getNews,
  getEventQuestions,
  editEventQuestions,
  deleteEventQuestions,
  addEventQuestions,
  questionsList,
  questionsList_def,
  history,
  editNews,
  addNews,
  sendRequest,
  getEventRequests,
  eventRequests,
  editEventRequest,
  getQuestionAnswers,
  answers,
  loading,
  getEventStages,
  addEventStage,
  editEventStage,
  deleteEventStage,
  stages,
  removeStageRequest,
  getListForExcel,
  accessRights,
  excelList,
  deleteFile,
  getEventFiles,
  uploadEventFiles,
  filesList,
  deleteEventFile,
  loadingbut,
  regionsList,
  getRegions,
  getDepartments,
  getCurrentNew,
  setCurrentEvent,
  getAnimations,
  animationsList,
  categories,
  getCategories,
  deps,
  removeCategories,
  userInfo,
  tasks,
  getTaskObject,
  goClick,
  saveAndClick,
  getAuthors,
  authors,
  ...props
}) => {

  const addMode = pathname.includes("add");
  const updateRights = addMode || accessRights.ac_update || formValue?.userid === userInfo.id;
  const newsId = props.match.params.newId;

  const [image, setImage] = useState(null);
  const [questionsMode, setQuestionsMode] = useState(false);
  const [requestsMode, setRequestsMode] = useState(false);
  const [filesMode, setFilesMode] = useState(false);
  const [regId, setRegId] = useState(false);
  const [type, setType] = useState(null);
  const [isChecked, setIsChecked] = useState([]);
  const [iconsList, setIconsList] = useState(false);
  const [icon, setIcon] = useState("");
  const [firstRenderCheck, setFirstRenderCheck] = useState(false);
  const [confirmation, setConfirmation] = useState({ txt: null, type: null });
  const [localCategories, setLocalCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [author, setAuthor] = useState({ value: null, label: null });
  const [editCategory, setEditCategory] = useState(null);

  const popUpRef = React.useRef();
  const catDescrRef = React.useRef();

  useEffect(() => {
    getRegions();
    getDepartments();
    getAuthors();
    removeCategories();
    if (newsId) {
      getCurrentNew(newsId);
      getCategories(newsId);
    }
    return () => {
      setCurrentEvent(null);
    };
  }, []);

  useEffect(() => {
    categories && !addMode && setLocalCategories(categories);
  }, [categories]);

  useEffect(() => {
    if (goClick === "quest" && initialValues?.id) {
      setQuestionsMode(true);
      saveAndClick(null);
    } else if (goClick === "files" && initialValues?.id) {
      setFilesMode(true);
      saveAndClick(null);
    }
  }, [goClick, initialValues?.id]);

  useEffect(() => {
    if (initialValues && initialValues.id) {
      const taskFilters = { events: [{ value: initialValues.id }] };
      getTaskObject(null, null, taskFilters);
      getEventStages(initialValues.id);
      getEventRequests(initialValues.id);
      getEventFiles(initialValues.id);
    }
  }, [initialValues?.id]);

  useEffect(() => {
    if (formValue && formValue.image) {
      setImage(formValue.image);
    } else {
      setImage(null);
    }

    if (formValue !== initialValues || (formValue && !initialValues)) {
      editedNewsUpdate(true);
    }
  }, [formValue, initialValues]);

  useEffect(() => {
    document.body.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.body.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (
      pathname === "/news/add_news" ||
      pathname === "/event/add_event" ||
      pathname === "/ads/add_ads"
    ) {
      fiels.map((item) =>
        change(
          item,
          item === "articledate" || item === "pubdate" ? new Date() : null
        )
      );
    } else {
      getCurrentNew(props.match.params.newId);
    }
  }, [pathname]);

  useEffect(() => {
    const val =
      (formValue?.region_list?.length > 0 &&
        (typeof formValue?.region_list[0] === "number"
          ? formValue?.region_list[0]
          : formValue?.region_list[0]?.value)) ||
      (initialValues?.region_list?.length > 0 &&
        initialValues?.region_list[0]) ||
      null;
    setRegId(val);
  }, [formValue]);

  useEffect(() => {
    initialValues &&
      initialValues.department_list &&
      initialValues.department_list[0] &&
      initialValues.department_list?.length > 0 &&
      setRegId(initialValues?.region_list[0]);

    if (initialValues?.level == "4") {
      setIsChecked(initialValues.region_list);
      setRegId(null);
    }
  }, [initialValues]);

  useEffect(() => {
    if (firstRenderCheck == false) {
      setFirstRenderCheck(true);
    }

    if (firstRenderCheck == true) {
      formValue.region_list = [];
      formValue.department_list = [];
    }

    if (typeof formValue?.level === "number") {
      formValue.region_list = [];
      formValue.department_list = [];
    }

    setRegId(null);
    if (!initialValues && formValue) {
      setIsChecked([]);
    }

    if (formValue?.level) {
      setType(formValue.level?.value || formValue?.level);
    } else if (initialValues?.level) {
      setType(initialValues?.level);
    } else {
      setType(null);
    }
  }, [formValue?.level?.value, initialValues?.level]);

  useEffect(() => {
    if (author?.value) {
      change("responsible_id", author?.value);
    }
  }, [author]);

  useEffect(() => {
    if (/edit/i.test(pathname) && initialValues) {
      setAuthor({
        value: initialValues?.responsible_id,
        label: initialValues?.responsible,
      });
    }
  }, [pathname, initialValues?.responsible_id]);

  useEffect(() => {
    if (userInfo.id && /add/i.test(pathname)) {
      setAuthor({ value: userInfo.id, label: userInfo.displayname });
    }
  }, [userInfo.id, pathname]);

  useEffect(() => {
    if (type && !initialValues && formValue) {
      setRegId(null);
      setIsChecked([]);
      formValue.region_list = [];
      formValue.department_list = [];
    }
  }, [type]);

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (popUpRef.current && !path.includes(popUpRef.current)) {
      setIconsList(false);
    }
    if (catDescrRef.current && !path.includes(catDescrRef.current)) {
      setEditCategory(null);
    }
  };

  const canEditQuestions = () => {
    const {nmo, nrsh, agsh, superuser} = userInfo
    const {level} = initialValues
    if (agsh || superuser) return true;
    if (level == 2 && nrsh) return true;
    if ((level == 3 || level == 4) && (nmo || nrsh)) return true;
    return false
  }

  const screen = () =>
    pathname === "/event/add_event" ? 2 : pathname === "/news/add_news" ? 1 : 3;

  const goToScreen = (val) => {
    if (formValue !== initialValues || (formValue && !initialValues)) {
      if (val) {
        editedNewsUpdate(false);
      } else {
        return false;
      }
    }

    let path = "";

    if (/event/i.test(pathname)) {
      path = PATHS.AUTH.EVENT;
    } else if (/news/i.test(pathname)) {
      path = PATHS.AUTH.NEWS;
    } else {
      path = PATHS.AUTH.ADS;
    }

    history.push(path);
  };

  const resetImage = () => change("image", null);

  const handleGetDep = (e) => {
    setRegId(deps.filter((el) => el.id == e.value)[0]?.regionId);
    formValue.department_list = [e];
    formValue.region_list = [e.value];
  };
  const handleLvl = (e) => {
    getDepartments();
    if (e !== null) {
      setRegId(e);
    }
  };

  const handleCheck = (code) => {
    if (isChecked.includes(Number(code))) {
      setIsChecked(isChecked.filter((item) => item != code));
    } else {
      setIsChecked((prev) => [...prev, Number(code)]);
    }
  };

  const handleNorm = () => {
    if (
      formValue?.region_list &&
      formValue?.region_list[0]?.value &&
      typeof formValue?.region_list[0].value === "number"
    ) {
      return regionsList
        .filter((el) => el.id === formValue?.region_list[0]?.value)[0]
        ?.domestic_departments?.map((it) => ({
          label: `${it.name}`,
          value: Number(it.id),
        }));
    } else if (initialValues?.region_list && initialValues?.region_list[0]) {
      return regionsList
        .filter((el) => el.id === initialValues?.region_list[0])[0]
        ?.domestic_departments?.map((it) => ({
          label: `${it.name}`,
          value: Number(it.id),
        }));
    } else return [];
  };



  const addNew = (values, toEvents) => {
    if (values.level && values.level.value) {
      values.level = values.level.value;
    }

    let errorField;
    !values.enddate && (errorField = "Дата завершения мероприятия");
    !values.startdate && (errorField = "Дата начала мероприятия");
    !values.level && (errorField = "Уровень мероприятия");
    !values?.responsible_id && (errorField = "Ответственный");

    values.level == 2 &&
      !values.department_list.length &&
      (errorField = "Региональное отделение");
    values.level == 3 &&
      !values.region_list.length &&
      (errorField = "Регион проведения");
    values.level == 4 && !isChecked.length && (errorField = "Список регионов");
    !values.title && (errorField = "Заголовок");
    if (errorField) {
      
      createNotification("error", `Поле '${errorField}' является обязательным`);
    } else {
      if (initialValues && /edit/i.test(pathname)) {
        const responsible_id = author.value || initialValues?.responsible_id
        const region_list = isChecked?.length
          ? isChecked
          : regId !== null
            ? [regId]
            : initialValues.region_list
              ? initialValues.region_list
              : [];
        const department_list = type === 4
          ? []
          : typeof(formValue.department_list[0]) === 'object'
            ? formValue.department_list?.map((it) => it.value)
            : formValue.department_list;
        editNews(
          values.id,
          {
            ...formValue,
            department_list,
            region_list,
            responsible_id
          },
          history,
          pathname,
          null,
          null,
          null,
          null,
          null,
          localCategories
        );
      } else {

        addNews(
          {
            articledate: moment(values.articledate).format("YYYY-MM-DD"),
            pubdate: moment(values.pubdate).format("YYYY-MM-DD"),
            articletype: screen(),
            linktext: values.linktext || "",
            linkurl: values.linkurl || "",
            participantlimit: Number(values.participantlimit) || 0,
            title: values.title || "",
            place: values.place || "",
            shortdescription: values.shortdescription || "",
            description: values.description || "",
            starred: false,
            userid: userId,
            level: values?.level || initialValues?.level || null,
            region_list:
              (isChecked?.length > 0 && isChecked) ||
              (regId !== null ? [regId] : []) ||
              [],
            source: "",
            registration_required: values.registration_required || false,
            department_list: values.department_list || [],
            status: 1,
            calendarDED: values.calendarDED || 0,
            fromdate:
              values.fromdate && moment(values.fromdate).format("YYYY-MM-DD"),
            todate: values.todate && moment(values.todate).format("YYYY-MM-DD"),
            startdate: moment(values.startdate).format("YYYY-MM-DD"),
            enddate: moment(values.enddate).format("YYYY-MM-DD"),
            image: values.image || null,
            icon: icon || "",
            responsible_id: author.value,
            age_from: values.age_from,
            age_to: values.age_to
          },
          history,
          pathname,
          toEvents,
          localCategories
        );
      }
    }
    editedNewsUpdate(false);
  };

  const closePopUp = (i) => {
    change("icon", i ? i.name : null);
    setIcon(i?.name || "");
    setIconsList(false);
  };

  const handleSaveAndClick = (val, types) => {
    if (/edit/i.test(pathname) && types == "quest") {
      setQuestionsMode(val);
    } else if (/edit/i.test(pathname) && types == "files") {
      setFilesMode(val);
    } else {
      setConfirmation((prev) => ({ ...prev, txt: "Продолжить", type: types }));
    }
  };

  const acceptConfirmation = (types) => {
    if (types == "quest") {
      addNew(formValue, "quest");
    } else if (types == "files") {
      addNew(formValue, "files");
    } else if (types == "all") {
      goToScreen(true);
    }

    setConfirmation({ txt: null, type: null });
  };

  const rejectConfirmation = () => {
    setConfirmation({ txt: null, type: null });
  };

  const addNewCategory = () => {
    setLocalCategories((prev) => [
      ...prev,
      {
        category: "Новая категория",
      },
    ]);
    setCurrentCategory(localCategories.length);
  };

  const handleSetCategoriesState = (ind) => (e) => {
    const name = e.target.name;
    const newLocalCategories = [...localCategories];
    newLocalCategories[ind][name] = e.target.value;
    newLocalCategories[ind].changed = true;
    setLocalCategories(newLocalCategories);
  };

  const handleDeleteCatItem = (ind) => (e) => {
    e.stopPropagation();
    const newLocalCategories = [...localCategories];
    newLocalCategories.splice(ind, 1);
    setLocalCategories(newLocalCategories);
  };

  const copyLinkHandler = () => {
    const link = `https://lk.yunarmy.ru/event/${newsId}`
    navigator.clipboard.writeText(link);
  };

  const optionsNormalize = (optionsList) => {
    if (optionsList) {
      const options = [];
      optionsList.map((item) =>
        options.push({ value: item.id, label: item.displayname })
      );
      return options;
    }
    return [];
  };

  const catItemClick = (ind) => (event) => {
    if (currentCategory === ind) {
      setEditCategory(ind);
      setCurrentCategory(null);
    } else {
      setCurrentCategory(ind);
    }
  };

  const renderTask = ({ name, id }) => {
    const params = {
      eventId: initialValues.id,
      taskId: id,
    };
    const queryString = new URLSearchParams(params).toString();
    return (
      <Link
        to={{
          pathname: "/tasks",
          search: `?${queryString}`,
        }}
        target="_blank"
      >
        <p>{name}</p>
      </Link>
    );
  };

  const title = `${/edit/i.test(pathname) ? "Редактирование" : "Добавление"} мероприятия`

  return (
    <div className="addNews">
      <div className="addNews__container container">
        
        <div className="newsListContainer__title">{title}</div>

        {confirmation.type && (
          <Confirmation
            top="30px"
            left="40px"
            title="Мероприятие будет сохранено."
            confirmation={confirmation.txt}
            acceptConfirmation={() => acceptConfirmation(confirmation.type)}
            rejectConfirmation={rejectConfirmation}
          />
        )}

        {loading && !requestsMode && !questionsMode && !filesMode && (
          <Loader top="60%" left="41%" />
        )}
        {questionsMode ? (
            <QuestionsMode
              eventId={initialValues.id}
              editEventQuestions={editEventQuestions}
              deleteEventQuestions={deleteEventQuestions}
              addEventQuestions={addEventQuestions}
              questionsList={questionsList}
              questionsList_def={questionsList_def}
              setQuestionsMode={setQuestionsMode}
              getEventStages={getEventStages}
              addEventStage={addEventStage}
              editEventStage={editEventStage}
              deleteEventStage={deleteEventStage}
              getAnimations={getAnimations}
              animationsList={animationsList}
              getEventQuestions={getEventQuestions}
              loading={loading}
              stages={stages}
              deleteFile={deleteFile}
              userInfo={userInfo}
              canEdit={canEditQuestions()}
            />
          ) : requestsMode ? (
            <RequestsMode
              initialValues={initialValues}
              eventRequests={eventRequests}
              setRequestsMode={setRequestsMode}
              editEventRequest={editEventRequest}
              getQuestionAnswers={getQuestionAnswers}
              removeEventRequest={removeStageRequest}
              answers={answers}
              loading={loading}
              loadingbut={loadingbut}
              getListForExcel={getListForExcel}
              excelList={excelList}
              regionsList={regionsList}
              getRegions={getRegions}
            />
          ) : filesMode ? (
            <FilesMode
              eventId={initialValues.id}
              setFilesMode={setFilesMode}
              files={filesList}
              userInfo={userInfo}
              uploadEventFiles={uploadEventFiles}
              deleteEventFile={deleteEventFile}
            />
          ) : 
        <div className="addNewsBody">
          <div className="addNewsBody__left">
              <p className="news__label">Заголовок</p>
              <TextField
                name="title"
                className="newsTitle"
                disabled={!updateRights}
              />

              <p className="news__label">Место проведения</p>
              <TextField
                name="place"
                className="newsTitle"
                disabled={!updateRights}
              />

              <p className="news__label">Ответственный</p>

              <div style={{ marginBottom: "1rem" }}>
                <SelectAndSearch
                  placeholder="Выбор автора"
                  options={optionsNormalize(authors)}
                  setState={setAuthor}
                  state={author}
                />
              </div>

              <p className="news__label">Возраст (от - до)</p>
              
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{display: "flex", gap: "1rem"}}>
                  <TextField
                    type="number"
                    name="age_from"
                    className="newsTitle"
                    disabled={!updateRights}
                  />  
                  -
                  <TextField
                    type="number"
                    name="age_to"
                    className="newsTitle"
                    disabled={!updateRights}
                  />  
                </div>
                {newsId &&
                  <div className={styles.copyLinkBlock} onClick={copyLinkHandler}>
                    <span>Копировать ссылку на мероприятие</span>
                    <img src={copyImg} alt='o_O'/>
                  </div>
                }
              </div>

              <p className="news__label">Текст</p>
              <WysiwygField
                className="newsText"
                name="description"
                readOnly={!updateRights}
              />

              <p className="news__label">Краткое описание</p>
              <TextAreaField
                name="shortdescription"
                className="newsText newsText2"
                disabled={!updateRights}
              />

              {!addMode && (
                <>
                  <p className="news__label news__label_mt">
                    Задания к мероприятию:
                  </p>
                  <div className={styles.taskModule}>
                    {tasks?.map(renderTask)}
                  </div>
                </>
              )}

              <p className="news__label news__label_mt">Категории:</p>
              <div className={styles.categoriesBlock}>
                {localCategories &&
                  localCategories.map((category, ind) => {
                    const divWidth =
                      category.category.split("").length * 9.1 + 30;
                    return (
                      <div
                        key={ind}
                        onClick={catItemClick(ind)}
                        className={styles.catItem}
                        style={{ width: `${divWidth}px` }}
                        ref={ind === editCategory ? catDescrRef : null}
                      >
                        {currentCategory === ind ? (
                          <input
                            value={category.category}
                            onChange={handleSetCategoriesState(ind)}
                            onBlur={() => setCurrentCategory(null)}
                            name="category"
                            autoFocus
                          />
                        ) : (
                          category.category
                        )}
                        <IoIosClose onClick={handleDeleteCatItem(ind)} />
                        {editCategory === ind && (
                          <div
                            className={styles.categoryDescription}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <h3>Описание:</h3>
                            <textarea
                              value={category.description}
                              onChange={handleSetCategoriesState(ind)}
                              name="description"
                              autoFocus
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                <div onClick={addNewCategory}>
                  <IoIosAdd size={20} color="white" />
                </div>
              </div>

              {initialValues && (
                <div
                  onClick={() => handleSaveAndClick(true, "quest")}
                  className={styles.goForward}
                >
                  Вопросы к мероприятию
                  <IoIosArrowRoundForward size={20} />
                </div>
              )}
              {initialValues && (
                <div
                  onClick={() => setRequestsMode(true)}
                  className={styles.goForward}
                >
                  Заявки на участие
                  <IoIosArrowRoundForward size={20} />
                </div>
              )}
              {initialValues && (
                <div
                  onClick={() => handleSaveAndClick(true, "files")}
                  className={styles.goForward}
                >
                  Файлы к мероприятию
                  <IoIosArrowRoundForward size={20} />
                </div>
              )}
            </div>
          <div className="addNewsBody__right">
            <p className="news__label">Фото</p>
            <div className="news-right-foto">
              <ImageField name="image" className="news-fotoBlock" />
              {image && updateRights && (
                <div className="news-right-foto__btns">
                  <button onClick={resetImage}>Очистить</button>
                </div>
              )}
            </div>

            <p className="news__label">Источник</p>
            <TextField
              name="linktext"
              className="news-source"
              disabled={!updateRights}
            />

            <p className="news__label">Ссылка на источник</p>
            <TextField
              name="linkurl"
              disabled={!updateRights}
              className="news-linkSource"
            />

            <p className="news__label news__label_mt">
              Дата начала мероприятия:
            </p>
            <DatePickerField
              name="startdate"
              className="news-datepicker"
              readOnly={!updateRights}
            />

            <p className="news__label news__label_mt">
              Дата завершения мероприятия:
            </p>
            <DatePickerField
              name="enddate"
              className="news-datepicker"
              readOnly={!updateRights}
            />

            <p className="news__label news__label_mt">Дата публикации:</p>
            <DatePickerField
              name="pubdate"
              className="news-datepicker"
              readOnly={!updateRights}
            />

            <p className="news__label news__label_mt">Тип мероприятия:</p>
            <DropdownField
              className="newsListHeader__dropdown"
              placeholder="Выбор типа"
              options={eventTypes}
              name="articletype"
              disabled={!updateRights}
              // onChange={() => { }}
            />

            <p className="news__label news__label_mt">Статус мероприятия:</p>
            <DropdownField
              className="newsListHeader__dropdown"
              placeholder="Выбор статуса"
              options={[]}
              name="authors"
              disabled={!updateRights}
              onChange={() => {}}
            />

            <p className="news__label news__label_mt">Уровень мероприятия:</p>
            <DropdownField
              className="newsListHeader__dropdown"
              placeholder="Выбор уровня"
              options={eventLevels.map((it) => ({
                label: it.name,
                value: it.value,
              }))}
              name="level"
              disabled={!updateRights}
              onChange={() => {}}
            />

            {type == 3 && regionsList && regionsList.length > 0 ? (
              <>
                <p className="news__label news__label_mt">
                  Регион проведения мероприятия:
                </p>
                <DropdownField
                  className="newsListHeader__dropdown"
                  placeholder="Регион проведения"
                  options={regionsList.map((it) => ({
                    label: `${it.regionName} ${it.federalUnit}`,
                    value: it.id,
                  }))}
                  name="region_list[0]"
                  onChange={(e) => handleLvl(e.value)}
                />
              </>
            ) : (
              <></>
            )}

            {type == 2 ? (
              <>
                <p className="news__label news__label_mt">
                  Региональное отделение:
                </p>
                <DropdownField
                  className="newsListHeader__dropdown"
                  placeholder="Выбрать отделение"
                  options={
                    deps
                      ? [...deps].map((it) => ({
                          label: it.name,
                          value: it.id,
                        }))
                      : []
                  }
                  name="department_list[0]"
                  onChange={(e) => handleGetDep(e)}
                />
              </>
            ) : (
              <></>
            )}

            {type == 3 &&
            regionsList &&
            regionsList?.length > 0 &&
            handleNorm().length > 0 ? (
              <>
                <p className="news__label news__label_mt">
                  Департамент мероприятия:
                </p>
                <DropdownField
                  className="newsListHeader__dropdown"
                  placeholder="Департамент"
                  options={handleNorm()}
                  name="department_list[0]"
                  onChange={(e) => console.log(e)}
                />
              </>
            ) : (
              <></>
            )}

            {type == 4 && regionsList && regionsList?.length > 0 ? (
              <>
                <p className="news__label news__label_mt">Список регионов:</p>

                <div className="regionWrapper">
                  {regionsList &&
                    regionsList.map((region, index) => (
                      <div
                        key={`${region.regionName}_${index}`}
                        className={styles.regionItem}
                      >
                        <CustomCheckbox
                          label={`${region.regionName} ${region.federalUnit}`}
                          value={isChecked.includes(Number(region.id))}
                          onChange={() => handleCheck(region.id)}
                        />
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <></>
            )}

            <p className="news__label news__label_mt">
              Календарь дней единых действий:
            </p>
            <CheckboxField
              name="calendarDED"
              color="#d7001b"
              checked={formValue?.calendarDED}
            />

            <p className="news__label news__label_mt">Требуется регистрация:</p>
            <CheckboxField
              name="registration_required"
              color="#d7001b"
              checked={formValue?.registration_required}
            />

            <p className="news__label news__label_mt">Показывать результаты:</p>
            <CheckboxField
              name="show_result"
              color="#d7001b"
              checked={formValue?.show_result}
            />

            <p className="news__label news__label_mt">Участие без вступления в ЮА:</p>
            <CheckboxField
              name="external_participation"
              color="#d7001b"
              checked={formValue?.external_participation}
            />

            <p className="news__label news__label_mt">Ограничение количества участников:</p>
            <TextField
              name="participantlimit"
              className="newsTitle"
              type="number"
            />

            <div className={styles.iconBlock} ref={popUpRef}>
              <div
                className={styles.icon}
                style={iconsList ? { borderColor: "rgb(215, 0, 27)" } : null}
                onClick={() => setIconsList((prev) => !prev)}
              >
                {formValue?.icon && (
                  <img
                    src={icons.find((i) => i.name === formValue.icon).value}
                    alt="o_O"
                  />
                )}
              </div>
              {iconsList && (
                <div className={styles.iconList}>
                  <div onClick={() => closePopUp(null)} />
                  {icons.map((i) => (
                    <img
                      key={i.name}
                      onClick={() => closePopUp(i)}
                      src={i.value}
                      alt="о_О"
                    />
                  ))}
                </div>
              )}
            </div>

            <p className="news__label news__label_mt">Награда:</p>
            <AwardsBlock
              selected={formValue?.award_id}
              onChange={value => change("award_id", value)}
              rightMode
            />

            <div
              className={
                formValue && Number(formValue.participantlimit > 0)
                  ? "parmactive"
                  : "parm"
              }
            >
              <p className="news__label news__label_mt">
                Подача заявок на участие
              </p>

              <p className="news__label news__label_mt">с</p>
              <DatePickerField name="fromdate" className="news-datepicker" />

              <p className="news__label news__label_mt">по</p>
              <DatePickerField name="todate" className="news-datepicker" />
            </div>

            

          </div>
        </div>
        }
        {!questionsMode && (
          <div className="news-left-btns">
            {updateRights && (
              <button
                className="news-left-btn news-left-btn__save"
                onClick={handleSubmit(addNew)}
              >
                Сохранить
              </button>
            )}
            <button
              className="news-left-btn news-left-btn__close"
              onClick={() => setConfirmation({ text: "1", type: "all" })}
            >
              Закрыть
            </button>
          </div>
        )}
        {confirmation?.type == "all" && (
          <Confirmation
            top="30px"
            left="40px"
            title="Есть несохраненные данные."
            confirmation={"Уйти со страницы"}
            acceptConfirmation={() => acceptConfirmation(confirmation.type)}
            rejectConfirmation={() => setConfirmation({ text: "", type: "" })}
          />
        )}
      </div>
    </div>
  );
};

const AddEventForm = reduxForm({
  form: "news",
  enableReinitialize: true,
})(AddEvent);

const mapStateToProps = (state, props) => {
  const {schedule, task, users, departments, auth} = state;
  const { location } = props;
  const form = getFormValues("news")(state);
  const initialValues = /edit/i.test(location.pathname) ? (schedule.currentNew || eventPlug) : eventPlug 
  return {
    userId: auth.user.id,
    userInfo: auth.user,
    accessRights: auth.accessRights.find((i) => i.name === "news") || {},
    regDep: departments.region_departments,
    formValue: form,
    initialValues,
    pathname: location.pathname,
    questionsList: schedule.questions_list?.article_stage?.questions,
    questionsList_def: schedule.questions_list_def?.article_stage?.questions,
    eventRequests: schedule.eventRequests,
    answers: schedule.question_answers,
    loading: schedule.loading,
    stages: schedule.eventStages,
    excelList: schedule.excelList,
    filesList: schedule.eventFiles,
    loadingbut: schedule.loadingbut,
    deps: schedule.deps,
    localDep: schedule.localDep,
    animationsList: schedule.animations,
    authors: schedule.authors,
    goClick: schedule.goClick,
    categories: schedule.categories,
    tasks: task.data,
    regionsList: users.regionList,
    location
  };
};

export default connect(mapStateToProps, {
  addNews,
  getNews,
  editNews,
  editedNewsUpdate,
  getEventQuestions,
  addEventQuestions,
  deleteEventQuestions,
  editEventQuestions,
  removeStageRequest,
  getEventRequests,
  editEventRequest,
  getQuestionAnswers,
  getEventStages,
  getCurrentNew,
  addEventStage,
  editEventStage,
  deleteEventStage,
  getListForExcel,
  getCategories,
  deleteFile,
  getEventFiles,
  removeCategories,
  uploadEventFiles,
  deleteEventFile,
  getRegions,
  getDepartments,
  setCurrentEvent,
  getAnimations,
  getTaskObject,
  saveAndClick,
  getAuthors,
})(AddEventForm);
