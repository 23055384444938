import React, { useState, useEffect } from "react";
import styles from "./Questions.module.css";
import EditQuestion from "./EditQuestion/EditQuestion";
import {
  IoIosCheckmark,
  IoIosCloseCircle,
  IoMdCreate,
  IoIosClose,
} from "react-icons/io";
import { InputFile } from "../../../assets/common/InputFile/InputFile";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { useSelector } from "react-redux";
import { createNotification } from "../../../components/Notifications/Notifications";
import AnimationComponent from "../../../components/AnimationComponent/AnimationComponent";

const emptyAnimation = { animation: {} }


const emptyQuestion = {
  question: "",
  question_image: null,
  option_images: [
    null,
    null,
    null
  ],
  type: "choice",
  animations: [
    emptyAnimation,
    emptyAnimation,
    emptyAnimation
  ],
  options: [
    {
      answer: "",
      is_correct: true,
      order_by: 1
    },
    {
      answer: "",
      is_correct: true,
      order_by: 1
    },
    {
      answer: "",
      is_correct: true,
      order_by: 1
    }
  ],
  weight: 5,
  required: true,
  correct_answers_count: 1,
  order_by: 1
};

function Questions ({
  loading,
  data = [],
  getEventQuestions,
  deleteEventQuestions,
  editEventQuestions,
  addEventQuestions,
  eventId,
  stageId,
  deleteFile,
  questionsList_def,
  edit,
  setEdit,
  fullQuestion,
  setFullQuestion,
  canEdit
}) {
  
  const [questionState, setQuestionState] = useState("");
  const [newQuestion, setNewQuestion] = useState(JSON.parse(JSON.stringify({...emptyQuestion, stage: stageId})));
  const [filesList, setFilesList] = useState([])

  const { categories, animations } = useSelector(state => state.schedule)


  const correctAnswersCount = newQuestion?.options.filter(opt => opt.is_correct).length
  
  useEffect(() => {
    const arr = [...newQuestion.options]
    const newData = {...newQuestion}
    if (correctAnswersCount>1) {
      delete newData.weight
      arr.forEach(opt => !opt.hasOwnProperty('points') && (opt.points = null))
    } else {
      arr.forEach(opt => delete opt.points)
      newData.weight = 5
    }
    setNewQuestion({...newData, options: arr });
  }, [correctAnswersCount])
  
  useEffect(() => {
    stageId && getEventQuestions(stageId)
  }, [stageId])
  
  useEffect(() => {
    setNewQuestion(JSON.parse(JSON.stringify({...emptyQuestion, stage: stageId})))
  }, [data])
  
  
  const setTestData = (val, i) => {
    let newData = [...data];
    newData[i] = val;
  };

  const handleEditAnswer = (value, ind, orderBy) => {
    const arr = [...newQuestion.options];
    arr[ind][orderBy ? 'order_by' : 'answer'] = value;
    setNewQuestion((prev) => ({ ...prev, options: arr }));
  };


  const handleAddNewQuestion = async () => {
    
    let errorText

    const obj = {...newQuestion}
    const optionsQuestion = questionState === "addOptionsQuestion" 
    const dndQuestion = questionState === "addDndQuestion" 
    const wordQuestion = questionState === "addWordQuestion" 
    const supplementQuestion = questionState === "addSupplementQuestion" 
    const imagesLength = obj.option_images.filter(i => i).length
    const optionsLength = obj.options.length

    dndQuestion && imagesLength !== optionsLength && (errorText = 'Количество изображений должно соответствовать количеству вариантов ответа')
    correctAnswersCount < 2 && !newQuestion.weight && (errorText = 'Поле баллы является обязательным')

    
    if (errorText) return createNotification('error', errorText);
    
    if (optionsQuestion || dndQuestion || wordQuestion || supplementQuestion) {
      const hasCorrectAnswers = !!newQuestion.options.filter(opt => opt.is_correct).length
      if (optionsQuestion && !hasCorrectAnswers) return createNotification('error', 'Вопрос должен содержать хотя бы один правильный вариант ответа');
      await addEventQuestions(eventId, stageId, obj)
    } else {
      delete obj.options
      delete obj.option_images
      await addEventQuestions(eventId, stageId, obj, filesList)
    }


    setNewQuestion(JSON.parse(JSON.stringify(emptyQuestion)));
    setQuestionState("");
    setFilesList([])

  };

  const closeAddBlock = () => {
    setNewQuestion(JSON.parse(JSON.stringify(emptyQuestion)));
    setQuestionState("");
    setFilesList([])
  };

  const newItem = () => {
    let options = [...newQuestion.options]
    const animations = [...newQuestion.animations];
    const option_images = [...newQuestion.option_images];
    options.push({
      answer: "",
      is_correct: false,
      order_by: 1
    })
    animations.push(emptyAnimation)
    option_images.push(null)
    setNewQuestion(prev => ({...prev, options, animations, option_images}))
  }

  const deleteItem = (i) => {
    const options = [...newQuestion.options]
    const animations = [...newQuestion.animations];
    const option_images = [...newQuestion.option_images];
    options.splice(i, 1)
    animations.splice(i, 1)
    option_images.splice(i, 1)
    setNewQuestion(prev => ({...prev, options, animations, option_images}))
  }

  const toggleAnswerCorrect = (i) => {
    const arr = [...newQuestion.options]
    arr[i].is_correct = !newQuestion.options[i].is_correct
    setNewQuestion(prev => ({...prev, options: arr}))
  }

  const deleteCurrentImage = (ind) => {
    const arr = [...filesList]
    arr.splice(ind, 1)
    setFilesList(arr)
  }

  const handleAnimationChange = (ind) => (payload, descrMode) => {
    const newData = descrMode ? payload.target.value : payload
    const fieldName = descrMode ? 'description' : 'animation_id'
    const newAnimationsList = JSON.parse(JSON.stringify(newQuestion.animations))
    
    newAnimationsList[ind].animation[fieldName] = newData

    setNewQuestion(prev => ({...prev, animations: newAnimationsList}))
  }
  
  const editAnswer = (e, i) => {
    const {name, value} = e.target
    const options = [...newQuestion.options]
    options[i][name] = value || null
    setNewQuestion(prev => ({...prev, options}))
  }

  const editImageClick = (index) => () => {
    const answIndex = index === undefined ? '' : index
    const input = document.getElementById(`inputImage${answIndex}`)
    input.click()
  }

  const deleteImageClick = (index) => () => {
    if (index===undefined) {
      setNewQuestion(prev => ({...prev, question_image: null}))
    } else {
      const newOptionImages = [...newQuestion.option_images]
      newOptionImages[index] = null
      setNewQuestion(prev => ({...prev, option_images: newOptionImages}))
    }
  }

  const setNewImageFile = (index) => (e) => {
    const file = e.target.files[0]
    if (index===undefined) {
      setNewQuestion(prev => ({...prev, question_image: file}))
    } else {
      const newOptionImages = [...newQuestion.option_images]
      newOptionImages[index] = file
      setNewQuestion(prev => ({...prev, option_images: newOptionImages}))
    }
  }

  const questionTaskRender = () => {
    switch (questionState) {
      case 'addDndQuestion': return 'Текст задания:';
      case 'addUrlQuestion': return 'Задание (ссылка):';
      case 'addWordQuestion': return 'Опишите слово:';
      case 'addSupplementQuestion': return 'Введите текст:';
      default: return 'Введите текст вопроса:';
    }
  }

  const selectQuestType = (type) => () => {
    setQuestionState(type)
    switch (type) {
      case 'addWordQuestion': {
        const type = 'word'
        const options = [emptyQuestion.options[0]]
        const option_images = [emptyQuestion.option_images[0]]
        const animations = [emptyQuestion.animations[0]]
        setNewQuestion(prev => ({...prev, type, options, option_images, animations}))     
        break;
      }
      case 'addSupplementQuestion': {
        const type = 'supplement'
        setNewQuestion(prev => ({...prev, type}))     
        break;
      }
      case 'addOptionsQuestion': {
        const options = emptyQuestion.options.map(opt => ({...opt, is_correct: false}))
        setNewQuestion(prev => ({...prev, options}))   
        break;
      }
      case 'addDndQuestion': {
        const type = 'dnd'
        const additional_words = []
        const options = emptyQuestion.options.map(opt => ({...opt, is_correct: false}))
        setNewQuestion(prev => ({...prev, type, options, additional_words}))     
        break;
      }
      case 'addCaseQuestion': {
        const type = 'text'
        setNewQuestion(prev => ({...prev, type}))     
        break;
      }
      case 'addUrlQuestion': {
        const type = 'url'
        setNewQuestion(prev => ({...prev, type}))     
        break;
      }
      case 'addCreativeQuestion': {
        const type = 'file'
        setNewQuestion(prev => ({...prev, type}))     
        break;
      }
      default: break;
    }
  }

  const newQuestionTitleRender = () => {
    switch (questionState) {
      case 'addWordQuestion': return 'Укажите слово:';
      case 'addSupplementQuestion': return 'Укажите верные ответы (по порядку):';
      default: return 'Укажите варианты ответов на вопрос:';
    }
  }

  const renderQuestion = (questionData, index) => (
    <EditQuestion
      eventId={eventId}
      deleteEventQuestions={deleteEventQuestions}
      editEventQuestions={editEventQuestions}
      questionData={questionData}
      key={questionData.id}
      fullData={data}
      index={index}
      stageId={stageId}
      edit={edit}
      setEdit={setEdit}
      fullMode={fullQuestion===index}
      setFullMode={setFullQuestion}
      setTestData={(val) => setTestData(val, index)}
      deleteFile={deleteFile}
      questionsList_def={questionsList_def}
      getEventQuestions={getEventQuestions}
      canEdit={canEdit}
    />
  )

  return (
    <div className={styles.addTestBodyLeft}>
      <h4>Вопросы:</h4>
      <div className={styles.questionsBlock}>{data.map(renderQuestion)}</div>
      <div className={styles.addQuestionBlock}>
        {questionState && (
          <IoIosCloseCircle onClick={closeAddBlock} className={styles.closeButton} size={24}/>
        )}
        {questionState ? (
          questionState === "targetQuestion" ? (
            <div className={styles.selectQuestionTypeBlock}>
              <div>Выберите тип вопроса:</div>
              <button onClick={selectQuestType("addOptionsQuestion")}>
                Вопрос с вариантами ответов
              </button>
              <button onClick={selectQuestType("addCaseQuestion")}>
                Вопрос с текстовым ответом
              </button>
              <button onClick={selectQuestType("addUrlQuestion")}>
                Вопрос с ссылкой
              </button>
              <button onClick={selectQuestType("addCreativeQuestion")}>
                Вопрос с ответом файлом
              </button>
              <button onClick={selectQuestType("addDndQuestion")}>
                Вопрос с перетаскиванием
              </button>
              <button onClick={selectQuestType("addWordQuestion")}>
                Составить слово
              </button>
              <button onClick={selectQuestType("addSupplementQuestion")}>
                Дополнить текст
              </button>
            </div>
          ) : (
            <div className={styles.optionQuestionBlock}>
              <div>
                <>
                  <div className={styles.questionBlockTitle}>{questionTaskRender()}</div>
                  <textarea
                    value={newQuestion.question}
                    onChange={(e) =>
                      setNewQuestion((prev) => ({
                        ...prev,
                        question: e.target.value,
                      }))
                    }
                  />
                  {questionState !== 'addDndQuestion' &&
                  <div className={styles.imageBox}>
                    <>
                      <IoMdCreate onClick={editImageClick()} />
                      <IoIosClose onClick={deleteImageClick()} />
                    </>
                    <img
                      src={newQuestion.question_image
                                ? URL.createObjectURL(newQuestion.question_image)
                                : '/img/photoplace.png'}
                      alt='о_О'
                    />
                    <input
                      type="file"
                      id="inputImage"
                      accept="image/png, image/jpeg"
                      onChange={setNewImageFile()}
                      hidden
                    />
                  </div>}
                </>
                
                {["addOptionsQuestion", "addWordQuestion", "addSupplementQuestion"].includes(questionState) &&
                <div>
                  <div className={styles.questionBlockTitle}>{newQuestionTitleRender()}</div>
                <div className={styles.answersBlock}>
                  {!newQuestion.hasOwnProperty('weight') && <div className={styles.pointsRow}>Баллы</div>}
                  {newQuestion.options.map((item, ind) => {
                    const itemImage = newQuestion.option_images[ind]
                    return (
                      <div key={ind}>
                        <div className={styles.editAnswerItem}>
                        <div className={styles.editAnswer}>
                        <input
                          className={styles.inputFirstChild}
                          value={item.order_by}
                          onChange={(e) => handleEditAnswer(e.target.value, ind, true)}
                        />
                        <div
                          className={styles.selectedBlock}
                          onClick={() => toggleAnswerCorrect(ind)}
                        >
                          {!!item.is_correct && <IoIosCheckmark size={24} color="rgb(20, 99, 79)"/>}
                        </div>
                        <input
                          className={styles.inputLastChild}
                          value={item.answer}
                          onChange={(e) => handleEditAnswer(e.target.value, ind)}
                        />
                        <IoIosCloseCircle
                          onClick={() => deleteItem(ind)}
                          className={styles.deleteItem}
                          size={20}
                        />
                      </div>
                      {!newQuestion.hasOwnProperty('weight') &&
                      <input
                        name='points'
                        value={item.points}
                        onChange={(e) => editAnswer(e, ind)}
                        onWheel={(e) => e.currentTarget.blur()}
                        type="number"
                        />}
                      </div>
                      <>
                        <div>Реакция на ответ:</div>
                        <textarea
                          value={newQuestion.animations[ind]?.animation?.description}
                          onChange={e => handleAnimationChange(ind)(e, true)}
                        />
                        <AnimationComponent
                          value={newQuestion.animations[ind]}
                          options={animations}
                          onChange={handleAnimationChange(ind)}
                        />
                      </>
                      <div className={styles.categoryBlock}>
                        <span>Категория:</span>
                        <select
                          value={item.category}
                          onChange={(e) => editAnswer(e, ind)}
                          name='category'
                        >
                          <option value={''}>Без категории</option>
                          {categories.map(cat =>
                            <option value={cat.id}>{cat.category}</option>
                          )}
                        </select>
                      </div>
                      <div className={styles.imageBox}>
                      <>
                        <IoMdCreate onClick={editImageClick(ind)} />
                        <IoIosClose onClick={deleteImageClick(ind)} />
                      </>
                      <img
                        src={itemImage
                                  ? URL.createObjectURL(itemImage)
                                  : '/img/photoplace.png'}
                        alt='о_О'
                      />
                      <input
                        type="file"
                        id={`inputImage${ind}`}
                        accept="image/png, image/jpeg"
                        onChange={setNewImageFile(ind)}
                        hidden
                      />
                    </div>
                    </div>
                    )
                  })}
                  {questionState !== 'addWordQuestion' &&
                    <button className={styles.addButton} onClick={newItem}>
                      + добавить вариант ответа
                    </button>
                  }
                </div>
                </div>}

                {
                  questionState === "addDndQuestion" &&
                    <div>
                      <div className={styles.answersBlock}>
                        {!newQuestion.hasOwnProperty('weight') && <div className={styles.pointsRow}>Баллы</div>}
                        {newQuestion.options.map((item, ind) => {
                          const itemImage = newQuestion.option_images[ind]
                          return (
                            <div key={ind}>
                              <div className={styles.editAnswerItem}>
                              <div className={styles.editAnswer}>
                                <input
                                  className={styles.inputLastChild}
                                  value={item.answer}
                                  onChange={(e) => handleEditAnswer(e.target.value, ind)}
                                />
                                <IoIosCloseCircle
                                  onClick={() => deleteItem(ind)}
                                  className={styles.deleteItem}
                                  size={20}
                                />
                              </div>
                            {!newQuestion.hasOwnProperty('weight') &&
                              <input
                                name='points'
                                value={item.points}
                                onChange={(e) => editAnswer(e, ind)}
                                onWheel={(e) => e.currentTarget.blur()}
                                type="number"
                              />
                            }
                            </div>
                            <div className={styles.categoryBlock}>
                              <span>Категория:</span>
                              <select
                                value={item.category}
                                onChange={(e) => editAnswer(e, ind)}
                                name='category'
                              >
                                <option value={''}>Без категории</option>
                                {categories.map(cat =>
                                  <option value={cat.id}>{cat.category}</option>
                                )}
                              </select>
                            </div>
                            <div className={styles.imageBox}>
                              <>
                                <IoMdCreate onClick={editImageClick(ind)} />
                                <IoIosClose onClick={deleteImageClick(ind)} />
                              </>
                              <img
                                src={itemImage
                                          ? URL.createObjectURL(itemImage)
                                          : '/img/photoplace.png'}
                                alt='о_О'
                              />
                              <input
                                type="file"
                                id={`inputImage${ind}`}
                                accept="image/png, image/jpeg"
                                onChange={setNewImageFile(ind)}
                                hidden
                              />
                            </div>
                            <>
                              <div>Реакция на ответ:</div>
                              <textarea
                                value={newQuestion.animations[ind]?.animation?.description}
                                onChange={e => handleAnimationChange(ind)(e, true)}
                              />
                              <AnimationComponent
                                value={newQuestion.animations[ind]}
                                options={animations}
                                onChange={handleAnimationChange(ind)}
                              />
                            </>
                          </div>
                          )
                        })}
                        <button
                          className={styles.addButton}
                          onClick={newItem}
                        >
                          + добавить вариант ответа
                        </button>
                      </div>
                      <div className={styles.moreWordsBlock}>
                        <div className={styles.questionBlockTitle}>Дополнительные слова (через запятую):</div>
                        <textarea
                          value={newQuestion.additional_words?.join(',')}
                          onChange={e => setNewQuestion(prev => ({...prev, additional_words: e.target.value.split(',')}))}
                        />
                      </div>
                    </div>
                }
            
                {
                  newQuestion.hasOwnProperty('weight') &&
                    <>
                      <div className={styles.questionBlockTitle}>
                        Количество баллов:
                      </div>
                      <input
                        style={{ width: "50px" }}
                        onWheel={(e) => e.currentTarget.blur()}
                        value={newQuestion.weight}
                        type="number"
                        onChange={e => setNewQuestion(prev => ({...prev, weight: e.target.value || null}))}
                      />
                    </>
                }

                <>
                  <div className={styles.questionBlockTitle}>
                    Порядковый номер: 
                  </div>
                  <input
                    style={{ width: "50px" }}
                    onWheel={(e) => e.currentTarget.blur()}
                    type='number'
                    value={newQuestion.order_by}
                    onChange={(e) => setNewQuestion((prev) => ({ ...prev, order_by: e.target.value }))}
                  />
                </>

                <CustomCheckbox
                  label="вопрос является обязательным"
                  value={newQuestion.required}
                  onChange={() => setNewQuestion(prev => ({...prev, required: !prev.required}))}
                />
                {
                  !['supplement', 'word'].includes(newQuestion.type) &&
                    <CustomCheckbox
                    label="перемешивать ответы"
                    value={newQuestion.shuffle_answers}
                    onChange={() => setNewQuestion(prev => ({...prev, shuffle_answers: !prev.shuffle_answers}))}
                    />
                }
                {
                    <CustomCheckbox
                    label="прикреплять геопозицию"
                    value={newQuestion.is_need_geo}
                    onChange={() => setNewQuestion(prev => ({...prev, is_need_geo : !prev.is_need_geo}))}
                    />
                }

                {/* <>
                  <div className={styles.questionBlockTitle}>
                    Допустимое количество ответов: 
                  </div>
                  <input
                    style={{ width: "50px" }}
                    type='number'
                    value={newQuestion.correct_answers_count}
                    onChange={(e) => setNewQuestion((prev) => ({ ...prev, correct_answers_count: parseInt(e.target.value) }))}
                  />
                </> */}

              </div>
              <button onClick={handleAddNewQuestion}>Добавить</button>
            </div>
          )
        ) : (canEdit && 
              <button onClick={() => setQuestionState("targetQuestion")}>
                Добавить вопрос
              </button>
        )}
      </div>
    </div>
  );
}

export default Questions;