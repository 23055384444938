import React, { useState, useEffect } from "react";
import styles from "./AddEvent.module.css";
import Questions from "./Questions/Questions";
import moment from "moment";
import { IoMdCreate, IoIosClose, IoIosArrowRoundBack, IoIosCloseCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createNotification } from "../../components/Notifications/Notifications";
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { BACKEND_DOMAIN } from "../../utils/const";
import CheckMode from "./CheckMode/CheckMode";
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from "react-redux";
import { getEventStat } from "../../actions/news";
import { Link } from "react-router-dom";


const emptyStage = {
  name: "",
  description: "",
  passing_score: 10,
  start_date: moment(new Date()),
  end_date: moment(new Date()),
  serial_number: 1,
  counting_method: null,
};

const stageTimeOptions = [
  {label: 'Нет', value: ''},
  {label: 'Таймер', value: 'timer'},
  {label: 'Секундомер', value: 'stopwatch'}
]

export default function QuestionsMode({
  eventId,
  getEventQuestions,
  editEventQuestions,
  deleteEventQuestions,
  addEventQuestions,
  questionsList,
  questionsList_def,
  setQuestionsMode,
  getAnimations,
  animationsList,
  addEventStage,
  editEventStage,
  deleteEventStage,
  deleteFile,
  stages,
  loading,
  canEdit
}) {
  const [modStages, setModStages] = useState(JSON.parse(JSON.stringify(stages)));
  const [visibleStages, setVisibleStages] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [newStage, setNewStage] = useState({...emptyStage, article_id: eventId});
  const [addMode, setAddMode] = useState(false);
  const [questionEditMode, setQuestionEditMode] = useState(null);
  const [questionFullMode, setQuestionFullMode] = useState(null);
  // const [checkMode, setCheckMode] = useState(false);
  const [statModal, setStatModal] = useState(false)


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    !animationsList && getAnimations()
  }, [])
  

  useEffect(() => {
    setModStages(JSON.parse(JSON.stringify(stages)));
  }, [stages]);

  useEffect(() => {
    setNewStage((prev) => ({ ...prev, article_id: eventId }));
  }, [eventId]);

  const toggleVisible = (ind) => {
    if (typeof editMode !== "number") {
      if (visibleStages === ind) {
        setVisibleStages(null);
      } else {
        setVisibleStages(ind);
      }
    }
  };

  const handleCancel = () => {
    setEditMode(null);
    setModStages(JSON.parse(JSON.stringify(stages)));
  };

  const openStatModal = () => setStatModal(true)
  const closeStatModal = () => setStatModal(false)

  const toggleEditMode = (i) => {
    if (
      typeof editMode === "number" &&
      JSON.stringify(stages[i]) !== JSON.stringify(modStages[i])
    ) {
      editEventStage(stages[i].id, eventId, modStages[i]);
    }
    if (editMode === i) {
      const element = document.getElementById(`stage${i}`)
      element.scrollIntoView({behavior: "smooth"})
      setEditMode(null);
    } else {
      setEditMode(i);
    }
    setVisibleStages(i);
  };

  const handleDeleteStage = (ind) => {
    const stageId = stages[ind].id;
    deleteEventStage(stageId, eventId);
  };

  const handleAddStage = async () => {
    if (!newStage.name) return createNotification('error', 'Поле "Название этапа" является обязательным');
    const startDate = moment(newStage.start_date).format("YYYY-MM-DD");
    const endDate = moment(newStage.end_date).format("YYYY-MM-DD");
    const object = { ...newStage, start_date: startDate, end_date: endDate };
    const stageAdded = await addEventStage(eventId, object);
    if (!stageAdded) return;
    setNewStage({ ...emptyStage, article_id: eventId });
    setAddMode(false);
  };

  const handleEditNewStage = (e) => {
    const {name, value} = e.target;
    const newObj = { ...newStage };
    if ([
      "shuffle_questions",
      "multiple_passing",
      "show_answers",
      "show_correct_answers",
      "ahead_schedule_score"
    ].includes(name)) {
      newObj[name] = !newObj[name];
    } else if (["start_image", "end_image"].includes(name)) {
      newObj[name] = e.target.files[0];
    } else if (name === 'mobile_time') {
      newObj[name] = value || null;
      value !== 'timer' && (newObj.time_limit = null);
    } else if (name === 'time_limit') {
      const intValue = parseInt(value)
      newObj[name] = intValue * 60 || null;
    } else if (name === 'passing_score') {
      newObj[name] = parseInt(value);
    } else {
      newObj[name] = value;
    }
    setNewStage(newObj);
  };

  const handleEditStage = (i, e) => {
    const {name, value} = e.target;
    const newObj = { ...modStages[i] };
    if ([
      "shuffle_questions",
      "multiple_passing",
      "show_answers",
      "show_correct_answers",
      "ahead_schedule_score"
    ].includes(name)) {
      newObj[name] = !newObj[name];
    } else if (["start_image", "end_image"].includes(name)) {
      newObj[name] = e.target.files[0];
    } else if (name === 'mobile_time') {
      newObj[name] = value || null;
      value !== 'timer' && (newObj.time_limit = null);
    } else if (name === 'time_limit') {
      const intValue = parseInt(value)
      newObj[name] = intValue * 60 || null;
    } else if (name === 'passing_score') {
      newObj[name] = parseInt(value);
    } else {
      newObj[name] = value;
    }
    const newArr = [...modStages];
    newArr[i] = newObj;
    setModStages(newArr);
  };

  const goBack = () => {
    if (typeof editMode === "number" || typeof questionEditMode === "number") {
      createNotification(
        "error",
        "Имеются несохраненные данные, необходимо завершить редактирование"
      );
    } else {
      setQuestionsMode(false);
    }
  };

  const htmlParse = (html) => {
    const reactElement = html && parse(html);
    return reactElement;
  };

  const editImageClick = (inputId) => () => {
    const input = document.getElementById(inputId);
    input.click();
  };

  const deleteImageClick = (fieldName, index) => () => {
    const newStageMode = index===undefined
    const newObj = newStageMode
      ? {...newStage}
      : {...modStages[index]}
    newObj[fieldName] = null
    if (newStageMode) {
      setNewStage(newObj)
    } else {
      const newArr = [...modStages];
      newArr[index] = newObj;
      setModStages(newArr);
    }
  };

  const renderTimeOptions = ({label, value}) => <option key={label} value={value}>{label}</option>

  const getMinutesValue = (seconds) => {
    const intTimeValue = parseInt(seconds)
    return intTimeValue ? intTimeValue  / 60 : null 
  }

  return (
    <>
      {/* checkMode
      ? <CheckMode
          goBack={() => setCheckMode(false)}
        />
      :  */<div className={styles.container}>
          <div onClick={goBack} className={styles.goBack}>
            <IoIosArrowRoundBack size={20} />
            назад
          </div>
          {stages && modStages?.map((it, i) => {
              const timeModeWord = stageTimeOptions.find(({value}) => value === (it.mobile_time || '')).label
              const timeValue = getMinutesValue(it.time_limit)
              return (
                <div
                  id={`stage${i}`}
                  className={`${styles.stageWrap} ${i !== visibleStages && styles.unopened}`}
                >
                  {/* <button onClick={() => setCheckMode(true)} className={styles.needCheckButton}>Требует проверки</button> */}
                  {canEdit && (
                    <div className={styles.stageNavBlock}>
                      {typeof editMode !== "number" && (
                        <IoMdCreate size={18} onClick={() => toggleEditMode(i)} />
                      )}
                      <IoIosClose
                        size={28}
                        title="Удалить"
                        onClick={() => handleDeleteStage(i)}
                      />
                    </div>
                  )}
                  {editMode === i && (
                    <div className={styles.buttonsBlock}>
                      <button
                        className={styles.buttonOk}
                        onClick={() => toggleEditMode(i)}
                      >
                        ОК
                      </button>
                      <button
                        className={styles.buttonСancel}
                        onClick={handleCancel}
                      >
                        Отмена
                      </button>
                    </div>
                  )}
                  {editMode == i ? (
                    <input
                      value={it.name}
                      className={styles.stageTitleInput}
                      name="name"
                      onChange={(e) => handleEditStage(i, e)}
                    />
                  ) : (
                    <h3
                      className={styles.stageTitle}
                      onClick={() => toggleVisible(i)}
                    >{`${it.name} ${visibleStages === i ? "-" : "+"}`}</h3>
                  )}
                  {visibleStages === i && (
                    <div className={styles.stageBlock}>
                      <div>
                        <h4 className={styles.stageBlockTitle}>Описание:</h4>
                        <div>
                          {editMode == i ? (
                            <textarea
                              value={it.description}
                              name="description"
                              onChange={(e) => handleEditStage(i, e)}
                            />
                          ) : (
                            <div>{it.description}</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <h4 className={styles.stageBlockTitle}>Даты проведения:</h4>
                        {editMode == i ? (
                          <div className={styles.datesBlock}>
                            <div>
                              <span>с</span>
                              <DatePicker
                                selected={
                                  it?.start_date ? moment(it?.start_date) : null
                                }
                                showYearDropdown
                                onChange={(e) =>
                                  handleEditStage(i, {
                                    target: { name: "start_date", value: e },
                                  })
                                }
                              />
                            </div>
                            <div>
                              <span>по</span>
                              <DatePicker
                                selected={it?.end_date ? moment(it?.end_date) : null}
                                showYearDropdown
                                onChange={(e) =>
                                  handleEditStage(i, {
                                    target: { name: "end_date", value: e },
                                  })
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div>{`c ${moment(it.start_date).format(
                            "DD.MM.YYYY"
                          )} по ${moment(it.end_date).format("DD.MM.YYYY")}`}</div>
                        )}
                      </div>
                      <div className={styles.stageRow}>
                        
                        <div className={styles.pointsBlock}>
                          <h4 className={styles.stageBlockTitle}>Проходной балл:</h4>
                          <div>
                            {editMode == i ? (
                              <input
                                value={it.passing_score}
                                onWheelCapture={e => e.target.blur()}
                                onKeyPress={(event) => !/[0-9]/.test(event.key) && event.preventDefault()}
                                type="number"
                                name="passing_score"
                                onChange={(e) => handleEditStage(i, e)}
                              />
                            ) : (
                              <div>{it.passing_score}</div>
                            )}
                          </div>
                        </div>
                        
                        <div>
                          <h4 className={styles.stageBlockTitle}>
                            Способ подсчета результатов:
                          </h4>

                          {editMode == i ? (
                            <select
                              onChange={(e) => handleEditStage(i, e)}
                              name="counting_method"
                            >
                              <option selected={it?.counting_method == ""} value={""}>
                                Выбрать
                              </option>
                              <option
                                value="auto"
                                selected={it?.counting_method == "auto"}
                                defaultChecked
                              >
                                Автоматический подсчет
                              </option>
                              <option
                                value="manual"
                                selected={it?.counting_method == "manual"}
                              >
                                Ручной подсчет
                              </option>
                            </select>
                          ) : (
                            <div>
                              {it?.counting_method == "auto"
                                ? "Автоматический"
                                : it?.counting_method == "manual"
                                ? "Ручной"
                                : "Не выбран"}
                            </div>
                          )}
                        </div>

                        <div className={styles.stageNumberBlock}>
                          <h4 className={styles.stageBlockTitle}>Номер этапа:</h4>
                          <div>
                            {editMode == i ? (
                              <input
                                value={it.serial_number}
                                type="number"
                                name="serial_number"
                                onChange={(e) => handleEditStage(i, e)}
                              />
                            ) : (
                              <div>{it.serial_number}</div>
                            )}
                          </div>
                        </div>

                      </div>
                      <div className={styles.stageRow}>
                        
                        <div>
                            <h4 className={styles.stageBlockTitle}>Ограничение по времени:</h4>
                            {editMode == i
                              ? <select
                                  onChange={(e) => handleEditStage(i, e)}
                                  name='mobile_time'
                                  value={it.mobile_time}
                                >
                                  {stageTimeOptions.map(renderTimeOptions)}
                                </select>
                              : <div>{timeModeWord}</div>
                            }
                        </div>

                        {
                          it.mobile_time === 'timer' &&
                            <div>
                              <h4 className={styles.stageBlockTitle}>Время в минутах:</h4>
                              {editMode == i
                                ? <input
                                    value={timeValue}
                                    name='time_limit'
                                    style={{width: '30px'}}
                                    onKeyPress={(event) => !/[0-9]/.test(event.key) && event.preventDefault()}
                                    onChange={(e) => handleEditStage(i, e)}
                                  />
                                : <div>{timeValue}</div>  
                              }
                            </div>
                        }
                      </div>

                      <div className={styles.stageDescriptionBlock}>
                        <div>
                            <div>
                            <h4 className={styles.stageBlockTitle}>
                              Вступительный текст и картинка:
                            </h4>
                            {editMode === i ? (
                              <ReactQuill
                                className={styles.quill}
                                value={it.start_text}
                                onChange={(val) =>
                                  handleEditStage(i, {
                                    target: { name: "start_text", value: val },
                                  })
                                }
                              />
                            ) : (
                              <div>{htmlParse(it.start_text) || "..."}</div>
                            )}
                          </div>
                          <div className={styles.imageBox}>
                            {editMode === i &&
                            <>
                              <IoMdCreate onClick={editImageClick("start_image")} />
                              <IoIosClose onClick={deleteImageClick("start_image", i)} />
                            </>}
                            <img
                              src={
                                it.start_image
                                  ? it.start_image.id
                                    ? `${BACKEND_DOMAIN || ""}${it.start_image.path}/${
                                        it.start_image.name
                                      }`
                                    : URL.createObjectURL(it.start_image)
                                  : "/img/photoplace.png"
                              }
                              alt="о_О"
                            />
                            <input
                              type="file"
                              id="start_image"
                              accept="image/png, image/jpeg"
                              onChange={(e) => handleEditStage(i, e)}
                              name="start_image"
                              hidden
                            />
                          </div>
                        </div>
                        <div>
                        <div>
                            <h4 className={styles.stageBlockTitle}>Финальный текст и картинка:</h4>
                            {editMode === i ? (
                              <ReactQuill
                                className={styles.quill}
                                value={it.end_text}
                                onChange={(val) =>
                                  handleEditStage(i, {
                                    target: { name: "end_text", value: val },
                                  })
                                }
                              />
                            ) : (
                              <div>{htmlParse(it.end_text) || "..."}</div>
                            )}
                          </div>
                          <div className={styles.imageBox}>
                          {editMode === i &&
                          <>
                            <IoMdCreate onClick={editImageClick("end_image")} />
                            <IoIosClose onClick={deleteImageClick("end_image", i)} />
                          </>}
                            <img
                              src={
                                it.end_image
                                  ? it.end_image.id
                                    ? `${BACKEND_DOMAIN || ""}${it.end_image.path}/${
                                        it.end_image.name
                                      }`
                                    : URL.createObjectURL(it.end_image)
                                  : "/img/photoplace.png"
                              }
                              alt="о_О"
                            />
                            <input
                              type="file"
                              id="end_image"
                              accept="image/png, image/jpeg"
                              onChange={(e) => handleEditStage(i, e)}
                              name="end_image"
                              hidden
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.checkboxesBlock}>
                        <CustomCheckbox
                          label="перемешать вопросы"
                          color="rgb(105, 14, 14)"
                          value={it.shuffle_questions}
                          onChange={(e) => handleEditStage(i, e)}
                          name="shuffle_questions"
                          disabled={!(editMode===i)}
                        />
                        <CustomCheckbox
                          label="многократное прохождение"
                          color="rgb(105, 14, 14)"
                          value={it.multiple_passing}
                          onChange={(e) => handleEditStage(i, e)}
                          name="multiple_passing"
                          disabled={!(editMode===i)}
                        />
                        <CustomCheckbox
                          label="отображать правильные ответы"
                          color="rgb(105, 14, 14)"
                          value={it.show_correct_answers}
                          onChange={(e) => handleEditStage(i, e)}
                          name="show_correct_answers"
                          disabled={!(editMode===i)}
                        />
                        <CustomCheckbox
                          label="завершать досрочно при нехватке баллов"
                          color="rgb(105, 14, 14)"
                          value={it.ahead_schedule_score}
                          onChange={(e) => handleEditStage(i, e)}
                          name="ahead_schedule_score"
                          disabled={!(editMode===i)}
                        />
                        <CustomCheckbox
                          label="обзор ответов после завершения"
                          color="rgb(105, 14, 14)"
                          value={it.show_answers}
                          onChange={(e) => handleEditStage(i, e)}
                          name="show_answers"
                          disabled={!(editMode===i)}
                        />
                      </div>
                      <Questions
                        getEventQuestions={getEventQuestions}
                        eventId={eventId}
                        editEventQuestions={editEventQuestions}
                        deleteEventQuestions={deleteEventQuestions}
                        addEventQuestions={addEventQuestions}
                        data={questionsList}
                        questionsList_def={questionsList_def}
                        stageId={stages[i]?.id}
                        loading={loading}
                        deleteFile={deleteFile}
                        editMode={editMode}
                        edit={questionEditMode}
                        setEdit={setQuestionEditMode}
                        fullQuestion={questionFullMode}
                        setFullQuestion={setQuestionFullMode}
                        canEdit={canEdit}
                      />
                        {editMode !== i && <div className={styles.statButton} onClick={openStatModal}>Статистика по лидерам</div>}
                        <StatModal
                          stageId={it.id}
                          open={statModal}
                          closeModal={closeStatModal}
                        />
                    </div>
                  )}
                </div>
              );
            })}
          {addMode ? (
            <div className={styles.newStageWrap}>
              <IoIosCloseCircle
                onClick={() => setAddMode(false)}
                className={styles.closeNewStageButton}
                size={24}
              />
              <div className={styles.firstAddBlock}>
                <div className={styles.addStageTitle}>Название этапа:</div>
                <input
                  value={newStage.name}
                  name="name"
                  onChange={handleEditNewStage}
                />
              </div>
              <div>
                <div className={styles.addStageTitle}>Описание:</div>
                <textarea
                  value={newStage.description}
                  name="description"
                  onChange={handleEditNewStage}
                />
              </div>
              <div className={styles.fourAddBlock}>
                <div className={styles.addStageTitle}>Даты проведения:</div>
                <div>
                  <span>с</span>
                  <DatePicker
                    selected={moment(newStage.start_date)}
                    showYearDropdown
                    onChange={(e) =>
                      setNewStage((prev) => ({ ...prev, start_date: e }))
                    }
                  />
                  <span>по</span>
                  <DatePicker
                    selected={moment(newStage.end_date)}
                    showYearDropdown
                    onChange={(e) =>
                      setNewStage((prev) => ({ ...prev, end_date: e }))
                    }
                  />
                </div>
              </div>
              <div className={styles.stageRow} style={{margin: '.4rem 0'}}>
                <div className={styles.thirdAddBlock}>
                  <div className={styles.addStageTitle}>Проходной балл:</div>
                  <input
                    value={newStage.passing_score}
                    type="number"
                    onWheelCapture={e => e.target.blur()}
                    onKeyPress={(event) => !/[0-9]/.test(event.key) && event.preventDefault()}
                    name="passing_score"
                    onChange={handleEditNewStage}
                  />
                </div>
                <div className={styles.thirdAddBlock}>
                  <div className={styles.addStageTitle}>
                    Способ подсчета результатов:
                  </div>
                  <select
                    name="counting_method"
                    onChange={(e) => handleEditNewStage(e)}
                  >
                    <option value="">{"Выбрать"}</option>
                    <option value="auto">Автоматический подсчет</option>
                    <option value="manual">Ручной подсчет</option>
                  </select>
                </div>
                <div className={styles.thirdAddBlock}>
                  <div className={styles.addStageTitle}>Номер этапа:</div>
                  <input
                    value={newStage.serial_number}
                    type="number"
                    name="serial_number"
                    onChange={handleEditNewStage}
                  />
                </div>
              </div>
              <div className={styles.stageRow}>
                        
                <div>
                  <div className={styles.addStageTitle}>Ограничение по времени:</div>
                  <select
                    onChange={handleEditNewStage}
                    name='mobile_time'
                    value={newStage.mobile_time}
                  >
                    {stageTimeOptions.map(renderTimeOptions)}
                  </select>
                </div>

                {
                  newStage.mobile_time === 'timer' &&
                    <div>
                      <div className={styles.addStageTitle}>Время в минутах:</div>
                      <input
                        value={getMinutesValue(newStage.time_limit)}
                        name='time_limit'
                        onChange={handleEditNewStage}
                        />
                    </div>
                }
              </div>
              <div className={styles.stageDescriptionBlock}>
                <div>
                  <div className={styles.addStageTitle}>Вступительный текст и картинка:</div>
                  <ReactQuill
                    className={styles.quill}
                    value={newStage.start_text || ''}
                    onChange={(val) => handleEditNewStage({ target: { name: "start_text", value: val } })}
                  />
                  <div className={`${styles.imageBox} ${styles.addImageBox}`}>
                    <>
                      <IoMdCreate onClick={editImageClick("start_image_new")} />
                      <IoIosClose onClick={deleteImageClick("start_image")} />
                    </>
                    <img
                      src={
                        newStage.start_image
                          ? newStage.start_image.id
                            ? `${BACKEND_DOMAIN || ""}${newStage.start_image.path}/${newStage.start_image.name}`
                            : URL.createObjectURL(newStage.start_image)
                          : "/img/photoplace.png"
                      }
                      alt="о_О"
                    />
                    <input
                      type="file"
                      id="start_image_new"
                      accept="image/png, image/jpeg"
                      onChange={(e) => handleEditNewStage(e)}
                      name="start_image"
                      hidden
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.addStageTitle}>Финальный текст и картинка:</div>
                  <ReactQuill
                    className={styles.quill}
                    value={newStage.end_text || ''}
                    onChange={(val) => handleEditNewStage({ target: { name: "end_text", value: val } })}
                  />
                  <div className={`${styles.imageBox} ${styles.addImageBox}`}>
                    <>
                      <IoMdCreate onClick={editImageClick("end_image_new")} />
                      <IoIosClose onClick={deleteImageClick("end_image")} />
                    </>
                    <img
                      src={
                        newStage.end_image
                          ? newStage.end_image.id
                            ? `${BACKEND_DOMAIN || ""}${newStage.end_image.path}/${newStage.end_image.name}`
                            : URL.createObjectURL(newStage.end_image)
                          : "/img/photoplace.png"
                      }
                      alt="о_О"
                    />
                    <input
                      type="file"
                      id="end_image_new"
                      accept="image/png, image/jpeg"
                      onChange={(e) => handleEditNewStage(e)}
                      name="end_image"
                      hidden
                    />
                  </div>
                </div>
              </div>
              <CustomCheckbox
                label="перемешать вопросы"
                color="rgb(105, 14, 14)"
                value={newStage.shuffle_questions}
                onChange={handleEditNewStage}
                name="shuffle_questions"
              />
              <CustomCheckbox
                label="многократное прохождение"
                color="rgb(105, 14, 14)"
                value={newStage.multiple_passing}
                onChange={handleEditNewStage}
                name="multiple_passing"
              />
              <CustomCheckbox
                label="отображать правильные ответы"
                color="rgb(105, 14, 14)"
                value={newStage.show_correct_answers}
                onChange={handleEditNewStage}
                name="show_correct_answers"
              />
              <CustomCheckbox
                label="завершать досрочно при нехватке баллов"
                color="rgb(105, 14, 14)"
                value={newStage.ahead_schedule_score}
                onChange={handleEditNewStage}
                name="ahead_schedule_score"
              />
              <CustomCheckbox
                label="обзор ответов после завершения"
                color="rgb(105, 14, 14)"
                value={newStage.show_answers}
                onChange={handleEditNewStage}
                name="show_answers"
              />
              <button onClick={handleAddStage} className={styles.addStageButton}>
                Добавить
              </button>
              <IoIosClose
                onClick={() => setAddMode(false)}
                className={styles.closeButton}
              />
            </div>
          ) : (
            canEdit && (
              <button
                onClick={() => setAddMode(true)}
                className={styles.addStageButton}
              >
                Добавить этап
              </button>
            )
          )}
        </div>}
    </>
  );
}


const StatModal = ({open, closeModal, stageId}) => {
  
  const { eventStat } = useSelector(state => state.schedule)
  const dispatch = useDispatch()

  useEffect(() => {
    open && dispatch(getEventStat(stageId))
  }, [open])
  
  const renderRow = (rowData, ind) => {
    const {best_score_date, best_time, name, number_of_attempts, percent_correct_answers, profile_id, score} = rowData
    const profileLink = `/profile/${profile_id}`
    const modifyDate = moment(best_score_date).format('LL')
    const modifyTime = moment.utc(best_time * 1000).format('mm:ss')
    return ( 
      <tr>
        <td>{ind+1}</td>
        <td><Link to={profileLink}>{name}</Link></td>
        <td>{score}</td>
        <td>{percent_correct_answers}</td>
        <td>{modifyTime}</td>
        <td>{modifyDate}</td>
        <td>{number_of_attempts}</td>
      </tr>
    )
  }
  
  return (
    <Modal
      showCloseIcon={false}
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.statModal}}
    >
      <h2>Статистика по лидерам</h2>
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Имя</th>
            <th>Баллы</th>
            <th>% верных ответов</th>
            <th>Затраченное время</th>
            <th>Дата</th>
            <th>Кол-во попыток</th>
          </tr>
        </thead>
        <tbody>
          {eventStat?.map(renderRow)}
        </tbody>
      </table>
    </Modal>
  )
}