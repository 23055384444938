import React, {useEffect, useState} from 'react'
import styles from '../Profile.module.scss'
import { ButtonRed } from '../../../assets/common/ButtonRed/ButtonRed'
import { IoIosArrowBack } from 'react-icons/io'
import FormInput from '../../../assets/common/FormInput/FormInput'
import FormSelect from '../../../assets/common/FormSelect/FormSelect'
import FormDatePicker from "../../../assets/common/FormDatePicker/FormDatePicker";
import { useDispatch, useSelector } from 'react-redux'
import { getProfileEvents } from '../../../actions/news'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import moment from 'moment'
import { useFormFilters } from '../../../hooks'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import SliderComp from './SliderComp'


const LEVELS = [
  { value: 1, name: "Федеральный" },
  { value: 2, name: "Региональный" },
  { value: 3, name: "Местный" },
  { value: 4, name: "Окружной" },
];

const LEVELS_OBJ = LEVELS.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.name }), {})

const ProfileTasks = ({ data, formik }) => {
  
  const dispatch = useDispatch()
  const history = useHistory()
  const { newsList } = useSelector(state => state.schedule)

  const {filters, updateFilter, resetFilters} = useFormFilters()

  useEffect(() => {
    if (!filters.events) return;
    dispatch(getProfileEvents(data.id, filters.events))
  }, [filters.events])
  
  const dateFormatted = date => date ? moment(date).format('YYYY-MM-DD') : ''

  const eventClickHandler = (eventId) => () => history.push(`/event/edit_event/${eventId}`)

  const renderRow = (event) => {
    const {id, level, title, startdate, enddate} = event
    const dates = `${dateFormatted(startdate)} - ${dateFormatted(enddate)}`
    return (
      <tr onClick={eventClickHandler(id)}>
        <td>{title}</td>
        <td>{dates}</td>
        <td>{LEVELS_OBJ[level]}</td>
      </tr>
    )
  }
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.fio}>
        {`${data.lastName} ${data.firstName} ${data.fathersName}`}
      </div>
      <div className={styles.descriptionWrap}>
        <div className={styles.description}>
          <div className={styles.buttonsTop}>
            <ButtonRed
              style={{ borderRadius: "5px", height: "30px" }}
              onClick={resetFilters}
            >
              <IoIosArrowBack size={22} />
              <span>НАЗАД</span>
            </ButtonRed>
          </div>
          <div className={styles.personalData}>
                <div className={styles.title}>Личные данные</div>
                <div className={styles.personalDataBlock}>
                  <div className={styles.personalDataLeft}>
                    <div className={styles.dataRow}>
                      <FormInput
                      onBlur={formik.handleBlur}
                        // width="250px"
                        title="Фамилия *"
                        value={formik.values.lastName}
                        notValid={formik.errors.lastName}
                        editMode={false}
                        name="lastName"
                        onChange={formik.handleChange}
                      />
                      <FormInput
                        // width="250px"
                        title="Имя *"
                        value={formik.values.firstName}
                        notValid={formik.errors.firstName}
                        editMode={false}
                        name="firstName"
                        onChange={formik.handleChange}
                      />
                      <FormInput
                        // width="250px"
                        title="Отчество *"
                        editMode={false}
                        value={formik.values.fathersName}
                        notValid={formik.errors.fathersName}
                        name="fathersName"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className={styles.dataRow}>
                      <FormSelect
                        // width="250px"
                        title="Пол *"
                        editMode={false}
                        value={formik.values.sex}
                        notValid={formik.errors.sex}
                        name="sex"
                        // options={selects.sex.slice(1)}
                        onChange={formik.handleChange}
                      />
                      <FormDatePicker
                        // width="250px"
                        title="День рождения *"
                        editMode={false}
                        value={formik.values.birthday}
                        notValid={formik.errors.birthday}
                        name="birthday"
                        onChange={formik.handleChange}
                        />
                      <FormInput
                        // width="250px"
                        title="Личный номер"
                        editMode={false}
                        value={formik.values.personalId}
                        notValid={formik.errors.personalId}
                        name="personalId"
                        onChange={formik.handleChange}
                      />
                    </div>
    
                      <div className={styles.dataRow}>
                      <FormSelect
                        // options={accessRights.ac_create
                        //   ? getRegionOptions(regions)
                        //   : getUserRegionsOptions(regions, userRegions)
                        // }
                        title="Регион *"
                        width="fit-content"
                        name="regionId"
                        value={formik.values.regionId}
                        // onChange={handleSetRegion}
                        editMode={false}
                        notValid={formik.errors.regionId}
                      />
                      
                      {
                        (formik.values.regionId == 96 || formik.values.regionId == 97) ? 
                        <FormSelect
                        // options={country.map((it) => ({
                        //   name: `${it.name || it.short_name}`,
                        //   value: it.id,
                        // }))}
                        title="Страна *"
                        width="fit-content"
                        name="country_id"
                        value={formik.values.country_id}
                        onChange={formik.handleChange}
                        editMode={false}
                        notValid={formik.errors.country_id}
                      />
                      :
                      <></>
                      }
                    </div>

                    
                    <div className={styles.dataRow}>
                      <div style={{margin: '0 .5rem'}}>
                      <FormInput
                        width="80px"
                        title="Индекс"
                        editMode={false}
                        value={formik?.values?.postalCode}
                        notValid={formik?.errors?.postalCode}
                        name="postalCode"
                        onChange={formik?.handleChange}
                        type="number"
                      />
                    </div>

                      <div style={{margin: '0 .5rem'}}>
                      <FormInput
                        width="300px"
                        title="E-mail"
                        editMode={false}
                        value={formik.values.email}
                        notValid={formik.errors.email}
                        name="email"
                        onChange={formik.handleChange}
                      />
                      </div>
                      <div style={{margin: '0 .5rem'}}>
                      <FormInput
                        width="180px"
                        title="Контактный телефон"
                        editMode={false}
                        value={formik.values.phone}
                        notValid={formik.errors.phone}
                        name="phone"
                        onChange={formik.handleChange}
                      />
                      </div>
                      <div style={{margin: '0 .5rem'}}>
                      <FormInput
                        width="150px"
                        title="VK ID"
                        editMode={false}
                        value={formik.values.vkid}
                        notValid={formik.errors.vkid}
                        name="vkid"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <SliderComp
                  // newID={newID}
                  // photos={photosHumon}
                  // editMode={editMode}
                  type="photo"
                  selectedItem={data}
                  // setGoEdit={setGoEdit}
                  // goEdit={goEdit}
                  // goAdd={goAdd}
                  // setEditMode={setEditMode}
                  // rotateDocumentImage={rotateDocumentImage}
                  isAvatar={true}
                />
              </div>
              <div className={styles.profileEventsBlock}>
                <CustomSelect
                  title='Уровень'
                  options={LEVELS}
                  value={filters.events}
                  selwidth='fit-content'
                  withoutFirst
                  onChange={(e) => updateFilter('events', e.target.value)}
                />
                <table>
                  <thead>
                    <tr>
                      <th>Мероприятие</th>
                      <th>Дата</th>
                      <th>Уровень</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!newsList?.length && <tr><td style={{textAlign: 'center'}} colSpan={3}>Данные отсутствуют</td></tr>}
                    {newsList?.map(renderRow)}
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileTasks